.side-navigation-additions {
    padding-left: 3em;
    padding-right: 2em;
}

.side-navigation-additions p {
    padding-bottom: .5em;
    margin-left: 2em;
    margin-right: 2em;
    font-size: .95em;
}

.create-team-button {
    padding-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
}