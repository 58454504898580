.column-title {
    text-align: center;
}

img {
    height: 8rem;
    border-radius: 100%;
}

.services {
    min-width: 300px;
    max-width: 450px;
}

.alias-view {
    text-align: center;
    min-width: 90px;
}

.create-ticket {
    text-align: center;
    min-width: 150px;
}

.team-display-name {
    text-align: center;
    height: 100%;
    min-width: 120px;
}

.team-name-container {
    min-width: 150px;
}

.time-remaining {
    min-width: 150px;
}

.update-team-button {
    text-align: right;
    margin-left: auto;
}

.awsui awsui-table table tbody tr td:first-child {
    height: 100%;
    min-width: 140px;
    max-width: 180px;
}

.team-page-link {
    margin-top: 10px;
}

.team-page-button .awsui-button {
    padding-right: 10px;
    padding-left: 10px;
}

.manager-info {
    text-align: center;
}

.shift-change {
    font-size: .9em;
}

.open-icon {
    padding: 0 5px;
}

.open-icon:hover {
    color: gray;
}

.org-section-link {
    display: flex;
}

.pillar-section-header {
    margin: 0;
    font-size: 18px;
}