.page-modal {
    text-align: left;
}

.page-modal .awsui .awsui-grid .col-9{
    flex: 0 0 100%;
    max-width: 100%;
}

.page-modal-button-container Button{
    margin-left: 1.5%;
    margin-right: 1.5%;
}