
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.spinner-position {
  position: absolute;
  left: 47.5%;
  top: 47.5%;
}