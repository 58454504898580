header {
  width: 100%;
  background:#232f3e;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

ul.menu-list {
  margin: 0;
  padding: 0 50px;
  background:#232f3e;
  list-style:none;
  height: 2.5em;
}

ul.menu-list > li {
  padding: .5em .5em;
  font-weight: bold;
}

ul.menu-list > li >  a {
  float: left;
  color :#ffffff;
  line-height: 16px;
}

ul.menu-list > li >  a, a:hover {
  cursor: pointer;
  text-decoration: none;
}

ul.menu-list.title > div {
  font-style: italic;
}

ul.menu-list > li.selected {
  font-size: 130%;
  font-weight: bold;
}

ul.menu-list > li.ml-auto {
  margin-left: auto;
}

ul.menu-list > li > img {
  padding-top: 13px;
  border-radius: 50%;
  height: 110%;
}