.input-container {
    display: flex;
    justify-content: space-around;
}

.half-width-input {
    width: 45%;
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.quarter-width-input {
    width: 22%;
    margin-left: 1.5%;
    margin-right: 1.5%;
}

.textarea-container {
    margin-left: 2.5%;
    margin-right: 2.5%;
}

.inline-input {
    display: inline-block;
}

.delete-button {
    float: right !important;
}

.hint-image-modal { 
    display: flex;
    align-content: center;
    justify-content: center;
    min-height: 400px;
}

.hint-image {
    height: 90%;
    width: 90%;
    border-radius: 0 !important;
}

.team-info-modal-buttons-container Button {
    margin-left: 1.5%;
    margin-right: 1.5%;
}